import React from "react";

import { DepotBin } from "depot/depot-bin";

export interface AddMultipleResponseTypes {
  serialNumber: string;
  status: string;
}

export interface AddMultipleCsvTypes {
  serialNumber: string;
  mac: string;
}

export interface MultipleDeviceReviewTypes {
  serialNumber: string;
  requestType: string;
  mac: string;
}

export interface MultipleDeviceUploadTypes extends MultipleDeviceReviewTypes {
  devicePool: string;
}

export const AddMultipleReviewValues = new DepotBin<AddMultipleResponseTypes[]>([]);

export const AddMultipleUploadedValues = new DepotBin<AddMultipleResponseTypes[]>([]);

import React, { useEffect, useMemo, useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useCSVReader } from "react-papaparse";
import { CommonSelect } from "components/common/common-select/common-select.component";
import { useRequest } from "hooks/useRequest";

import { DevicePoolTypes } from "pages/Pools/pools.config";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import { TableHeaderCell } from "components/table/table.style";
import { useNavigate } from "react-router";
import { exportDeviceSampleCsv } from "components/utilites/utilities";
import { Popup } from "components/common/popup/popup.component";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import { CommonText, NewPageTitle } from "components/common/text/text.component";
import { FieldValues, useForm } from "react-hook-form";
import { PrimaryButton, ButtonText, TextButton } from "components/common/Buttons";
import {
  AddMultipleResponseTypes,
  AddMultipleReviewValues,
  AddMultipleUploadedValues,
  MultipleDeviceReviewTypes,
} from "./add-multiple-devices.depot";
import {
  AddMultipleCsvLabels,
  AddMultipleRequests,
  AddMultipleStatus,
  reviewMultipleDevices,
  uploadDeviceData,
} from "./add-multiple-devices.helpers";
import DownloadDoneIcon from "../../assets/add-multiple-icons/icon_check_green.svg";
import ErrorIcon from "../../assets/add-multiple-icons/icon_error_red.svg";
import style from "./add-multiple-devices.module.css";

const EXCEEDS_LITMIT_ERROR =
  "An upload can not exceed more than 100 devices at a time. Please edit your CSV and try again.";

export default function AddMultipleDevices(): JSX.Element {
  const { CSVReader } = useCSVReader();
  const navigate = useNavigate();

  // below goes on table
  const [csvValues, setCsvValues] = useState<MultipleDeviceReviewTypes[]>([]);
  const [inventoryStatus, setInventoryStatus] = useState<AddMultipleResponseTypes[]>([]);
  const [slicedInventory, setSlicedInventory] = useState<AddMultipleResponseTypes[]>([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(false);
  const [isPoolUnSelected, setIsPoolUnselected] = useState<boolean>(true);
  const [constainsValidDevice, setContainsValidDevice] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const {
    formState: { errors },
    control,
    watch,
  }: FieldValues = useForm();
  const { data: poolsData } = useRequest("devicePools");

  // Retrieves Depot data for page
  useEffect(() => {
    const devicesReviewDepot = AddMultipleReviewValues.observable.subscribe({
      next: (data: AddMultipleResponseTypes[]) => {
        const containsValidDevice = data.find((value) => value.status === "Valid");

        if (!containsValidDevice) {
          setContainsValidDevice(false);
        } else {
          setContainsValidDevice(true);
        }

        setInventoryStatus(data);
      },
    });

    const devicesUploadedDepot = AddMultipleUploadedValues.observable.subscribe({
      next: (data: AddMultipleResponseTypes[]): void => {
        setInventoryStatus(data);
      },
    });
    return () => {
      devicesUploadedDepot.unsubscribe();
      devicesReviewDepot.unsubscribe();
    };
  }, []);

  const filteredInventory = useMemo(
    () =>
      inventoryStatus?.length > 0
        ? inventoryStatus.filter(
            (o, index, arr) => arr.findIndex((item) => JSON.stringify(item) === JSON.stringify(o)) === index,
          )
        : [],
    [inventoryStatus],
  );

  // Filters device inventory array for display
  useEffect(() => {
    const dividedIntentory = filteredInventory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setSlicedInventory(dividedIntentory);
  }, [inventoryStatus, page, rowsPerPage]);

  // Get selected Pool
  useEffect(() => {
    const pool: string | undefined = watch("devicePool");

    if (pool === undefined) {
      setIsPoolUnselected(true);
    } else {
      setIsPoolUnselected(false);
    }
  }, [watch("devicePool")]);

  const getDeviceStatus = async (inventory: MultipleDeviceReviewTypes[]): Promise<void> => {
    inventory.forEach(async (data: MultipleDeviceReviewTypes) => {
      setIsUploadDisabled(true);
      await reviewMultipleDevices(data);
      setIsUploadDisabled(false);
    });
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const poolOptions = useMemo(
    () =>
      poolsData?.length > 0
        ? poolsData.map(({ devicePool }: DevicePoolTypes) => ({ value: devicePool, label: devicePool }))
        : [],
    [poolsData],
  );

  const uploadDevices = async (): Promise<void> => {
    const pool = watch("devicePool");

    csvValues.forEach(async (data: MultipleDeviceReviewTypes) => {
      const newObj = {
        requestType: "",
        serialNumber: "",
        mac: "",
        devicePool: "",
      };

      newObj.requestType = AddMultipleRequests.Add;
      newObj.serialNumber = data.serialNumber;
      newObj.mac = data.mac;
      newObj.devicePool = pool;

      setIsUploadDisabled(true);

      await uploadDeviceData(newObj);

      setIsUploadDisabled(false);
    });
  };

  const downloadSampleCSV = () => {
    const combinedLabels = [AddMultipleCsvLabels.SN, AddMultipleCsvLabels.MAC];
    const dummyData = [
      { sn: 123456789012, mac: 123456789012 },
      { sn: 723456789012, mac: 723456789012 },
    ];

    exportDeviceSampleCsv(dummyData, combinedLabels);
  };

  const getRowIcon = (status: string): any => {
    if (status === AddMultipleStatus.Valid || status === AddMultipleStatus.Added) {
      return <img src={DownloadDoneIcon} alt="" />;
    }
    return <img src={ErrorIcon} alt="" />;
  };

  return (
    <>
      <CSVReader
        onUploadAccepted={(results: any) => {
          // clear data in depot before new CSV is checked
          AddMultipleReviewValues.setData([]);
          AddMultipleUploadedValues.setData([]);

          const newArr: MultipleDeviceReviewTypes[] = [];
          results.data.forEach(async (data: string[], i: number, array: string[][]) => {
            if (i < 1) {
              return;
            }

            const inventoryData: any = {
              requestType: AddMultipleRequests.Review,
              serialNumber: "",
              mac: "",
            };

            const [deviceSn, deviceMacAddr] = data;

            if (!deviceSn) {
              return;
            }
            const convertedSn: string = Number(deviceSn).toString();

            inventoryData.serialNumber = convertedSn;
            inventoryData.mac = deviceMacAddr;

            newArr.push(inventoryData);
          });

          if (newArr.length > 100) {
            setIsError(true);
            setErrorMessage(EXCEEDS_LITMIT_ERROR);
          } else {
            setCsvValues(newArr);
            getDeviceStatus(newArr);
          }
        }}
      >
        {({ getRootProps, ProgressBar }: any) => (
          <>
            <div className={style.topBarContainer}>
              <div className={style.titleContainer}>
                <NewPageTitle>Add Multiple Devices</NewPageTitle>
                <TextButton style={{ paddingLeft: 0, paddingTop: 0 }} onClick={() => navigate("/devices")}>
                  {"< Devices"}
                </TextButton>
              </div>
              <div>
                <NxtIconButton onClick={downloadSampleCSV} icon={<FileDownloadOutlinedIcon />} text="Sample CSV" />
              </div>
            </div>

            <div className={style.importAreaContainer}>
              <div className={style.searchButtonMargin}>
                <PrimaryButton disabled={isUploadDisabled} {...getRootProps()}>
                  {ButtonText.SelectCsv}
                </PrimaryButton>
              </div>

              <div className={style.uploadOptionsContainer}>
                <div style={{ paddingRight: "24px" }}>
                  <CommonSelect
                    label="Please select a Pool"
                    name="devicePool"
                    options={poolOptions}
                    width="250px"
                    control={control}
                    rules={{ required: "Device Pool is required" }}
                  />
                </div>

                <div className={style.searchButtonMargin}>
                  <PrimaryButton
                    onClick={uploadDevices}
                    disabled={!constainsValidDevice || isPoolUnSelected || isUploadDisabled}
                  >
                    {ButtonText.CsvUpload}
                  </PrimaryButton>
                </div>
              </div>
            </div>

            <ProgressBar class={style.progressBarColor} />
          </>
        )}
      </CSVReader>

      <TableContainer className={style.tableContainer} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={style.tableHeaderCell} />
              <TableHeaderCell className={style.tableHeaderCell}>Serial Number</TableHeaderCell>
              <TableHeaderCell className={style.tableHeaderCell}>Upload Status</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedInventory.map((row: AddMultipleResponseTypes) => {
              return (
                <TableRow key={row.serialNumber} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell className={style.tableHeaderCell} align="left">
                    {getRowIcon(row.status)}
                  </TableCell>
                  <TableCell className={style.tableHeaderCell} component="th" scope="row">
                    {row.serialNumber}
                  </TableCell>
                  <TableCell className={style.tableHeaderCell} component="th" scope="row">
                    {row.status}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className={style.paginationContainer}>
          <TablePagination
            component="div"
            count={filteredInventory.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>

      <Popup primaryBtn={{ onClick: () => setIsError(false), text: "Close" }} open={isError} title="Error">
        <CommonText>{errorMessage}</CommonText>
      </Popup>
    </>
  );
}

import React from "react";
import { IconButton } from "@mui/material";
import AddCircleOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { NxtIconButton } from "../nxt-icon-button/nxt-icon-button.component";
import { TitleText } from "../text/text.component";
import style from "./popup-header.module.css";

interface PopupHeaderProps {
  title: string;
  onClick?: () => void;
  isNewDevice?: boolean;
}

export function PopupHeader({ title, onClick, isNewDevice }: PopupHeaderProps): JSX.Element {
  console.warn("isnewdevice", isNewDevice);

  const getHeaderButton = () => {
    if (isNewDevice) {
      return (
        <div>
          <NxtIconButton icon={<AddCircleOutlined />} onClick={onClick} text="Multiple Devices" />
        </div>
      );
    }
    return (
      <div>
        <IconButton onClick={onClick}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  return (
    <div className={style.container}>
      <TitleText>{title}</TitleText>
      {onClick && getHeaderButton()}
    </div>
  );
}

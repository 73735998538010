import { Dialog, DialogContent, DialogProps } from "@mui/material";
import React, { ReactNode } from "react";
import { GhostButton, PrimaryButton } from "../Buttons";
import style from "./popup.module.css";
import { PopupHeader } from "./popup-header.component";

interface ButtonProps {
  onClick: () => void;
  text: string;
  disabled?: boolean;
}

interface PopupProps extends DialogProps {
  isNewDevice?: boolean;
  onHeaderClick?: () => void;
  primaryBtn?: ButtonProps;
  secondaryBtn?: ButtonProps;
  title: string;
  children: ReactNode;
  disableEscapeKeyDown?: boolean;
}

export function Popup({
  onHeaderClick,
  open,
  title,
  onClose,
  children,
  primaryBtn,
  disableEscapeKeyDown,
  secondaryBtn,
  isNewDevice,
}: PopupProps): JSX.Element {
  function getButtonContainerStyle() {
    if (primaryBtn === undefined && secondaryBtn === undefined) {
      return "";
    }

    const classes = [style.topMargin];

    if (!secondaryBtn) {
      classes.push(style.alignContentEnd);
    } else {
      classes.push(style.alignContentEvenly);
    }

    return classes.join(" ");
  }

  return (
    <Dialog
      className={style.container}
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <PopupHeader title={title} onClick={onHeaderClick} isNewDevice={isNewDevice} />
      <DialogContent>
        {children}

        <div className={getButtonContainerStyle()}>
          {secondaryBtn && (
            <div style={{ marginRight: "16px" }}>
              {" "}
              <GhostButton onClick={secondaryBtn.onClick}>{secondaryBtn.text}</GhostButton>
            </div>
          )}
          {primaryBtn && (
            <PrimaryButton disabled={primaryBtn.disabled} onClick={primaryBtn.onClick}>
              {primaryBtn.text}
            </PrimaryButton>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Controller, Control, FieldValues } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { OptionTypes } from "pages/configs/configs";
import style from "./common-select.module.css";

interface SelectProps {
  control: Control<FieldValues>;
  rules?: Record<string, string>;
  name: string;
  label?: string;
  options: OptionTypes[];
  width?: string;
  error?: any;
}

export function CommonSelect({ name, control, rules, options, width, ...props }: SelectProps): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl sx={{ width, fontSize: "14px" }} size="small">
          <TextField
            className={style.textField}
            select
            {...field}
            {...props}
            inputProps={{ "aria-label": "Without label" }}
          >
            {options.map(({ value, label }) => (
              <MenuItem sx={{ fontSize: "14px" }} key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      )}
    />
  );
}

import axiosInst from "api/config/axiosConfig";
import {
  AddMultipleResponseTypes,
  AddMultipleReviewValues,
  AddMultipleUploadedValues,
  MultipleDeviceReviewTypes,
  MultipleDeviceUploadTypes,
} from "./add-multiple-devices.depot";

export enum AddMultipleStatus {
  Claimed = " Rejected, SerialNumber is already claimed",
  InvalidData = "Rejected, Invalid device data",
  Valid = "Valid",
  Added = "Device added",
}

export enum AddMultipleRequests {
  Add = "Add",
  Review = "Review",
}

export enum AddMultipleCsvLabels {
  SN = "SN",
  MAC = "MAC",
}

export async function reviewMultipleDevices(data: MultipleDeviceReviewTypes) {
  try {
    const inventoryArray: AddMultipleResponseTypes[] = [];
    const response = axiosInst.post("/beta/devicesMultiple", data);
    const responseData = (await response).data;
    const inventoryDepotData = AddMultipleReviewValues.data;

    inventoryArray.push(...inventoryDepotData);
    inventoryArray.push(responseData);

    AddMultipleReviewValues.setData(inventoryArray);
  } catch (error: any) {
    console.warn("error", error.data);
  }
}

export async function uploadDeviceData(data: MultipleDeviceUploadTypes) {
  try {
    const inventoryArray: AddMultipleResponseTypes[] = [];
    const response = axiosInst.post("/beta/devicesMultiple", data);
    const responseData = (await response).data;
    const inventoryDepotData = AddMultipleUploadedValues.data;

    inventoryArray.push(...inventoryDepotData);
    inventoryArray.push(responseData);

    AddMultipleUploadedValues.setData(inventoryArray);
  } catch (error: any) {
    console.warn("error", error.data);
  }
}

import AddCircleOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { Menu, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { addSingleDeviceAPIHelper, changeDeviceStatusFromUI, deleteDevice } from "api/DeviceHelpers";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import { Popup } from "components/common/popup/popup.component";
import { Spinner } from "components/common/spinner/spinner.component";
import { PageTitle } from "components/common/Text";
import { onEnter } from "utils/general";
import { ActionBar } from "components/common/Wrapper";
import { exportCsv } from "components/utilites/utilities";
import { getAllDevicePools } from "api/DevicePoolHelpers";
import CustomTable from "components/table/table.component";
import { CommonSelect } from "components/common/common-select/common-select.component";
import { FieldError, StyledTextField } from "components/common/Input";
import { COLUMN_TYPE } from "utils/constants";
import { TableBodyCell } from "components/table/table.style";
import TableFilterNew from "components/table/TableFilterNew.component";
import { ButtonText } from "components/common/Buttons";
import useConfirm from "hooks/useConfirm";
import { DevicePoolTypes } from "pages/Pools/pools.config";
import { useRequest } from "hooks/useRequest";
import { showSuccess, showWarning } from "utils/notifications";
import { getCurrentPage, getFilteredListNew, getSortList } from "utils/util-functions";
import DASHBOARD_COLUMNS, { DeviceColumnTypes, DeviceValueTypes, eiInterfaceTypes } from "./devices.config";
import exportFileIcon from "../../assets/svg/file_export_24dp_BLUE.svg";
import style from "./devices.page.module.css";

export default function DevicesPage(): JSX.Element {
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [isOpenNewDeviceModal, setIsOpenNewDeviceModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [poolList, setPoolList] = useState<DevicePoolTypes[]>([]);
  const [filterColumn, setFilterColumn] = useState<string>("");
  const [filterWord, setFilterWord] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [updating, setUpdating] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("");
  const [isCreatingNewDevice, setIsCreatingNewDevice] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("Creating new device...");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  }: FieldValues = useForm();

  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(actionsAnchorEl);
  const handleActionsClick = (event: { currentTarget: HTMLElement }) => {
    setActionsAnchorEl(event.currentTarget);
  };
  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const [getConfirmation, Confirmation] = useConfirm();

  const filterColumns = DASHBOARD_COLUMNS.filter(({ filter }) => filter);
  const dataColumns = useMemo(() => {
    const columns = [...DASHBOARD_COLUMNS];
    columns.splice(0, 1);
    return columns;
  }, []);

  const poolOptions = useMemo(
    () => poolList.map(({ devicePool }) => ({ value: devicePool, label: devicePool })),
    [poolList],
  );

  const { data, isLoading, mutate } = useRequest("eiStatusUIHook");

  const totalDeviceCount = useMemo(() => data?.totalDeviceCount || 0, [data]);

  const deviceData = useMemo(() => {
    if (!data?.deviceData) return [];

    return data?.deviceData.map((value: DeviceValueTypes) => {
      const { eiInterfaces } = value;
      const wan = eiInterfaces.find((item: eiInterfaceTypes) => item.type === "WAN");

      return {
        ...value,
        txBytes: wan?.txBytes?.toString() || "",
        rxBytes: wan?.rxBytes?.toString() || "",
      };
    });
  }, [data]);

  const filteredList = useMemo(
    () => getFilteredListNew(deviceData, filterColumn, filterType, filterWord),
    [deviceData, filterColumn, filterWord, filterType],
  );

  const getDeviceDataCsv = () => {
    const combinedLabels = [
      "Device Alias",
      "Serial Number",
      "IMEI",
      "MAC",
      "SIM Phone Number",
      "APN",
      "Device Pool",
      "Software Version",
      "Device Description",
      "Last Check-In",
      "Online Status",
      "Location Tracking",
    ];

    const dataPoints = filteredList.map((device: DeviceValueTypes) => {
      const epochTime = new Date(device.lastCheckIn).getTime();
      const timeStamp = epochTime / 86400000 + 25569;

      return {
        deviceAlias: device.deviceAlias,
        serialNumber: device.eiSerialNumber,
        imei: device.imei,
        mac: device.mac,
        phoneNumber: device.phoneNumber,
        apn: device.apn,
        devicePool: device.devicePool,
        eiSoftwareVersion: device.eiSoftwareVersion,
        deviceDescription: device.deviceDescription,
        lastCheckIn: timeStamp,
        online: device.online ? "Online" : "Offline",
        locationTrailEnabled: device.locationTrailEnabled ? "Enabled" : "Disabled",
      };
    });

    return exportCsv(dataPoints, combinedLabels);
  };

  const sortedList: DeviceValueTypes[] = useMemo(() => {
    const sortColumn = DASHBOARD_COLUMNS.find(({ id }) => id === sortBy);
    return getSortList(filteredList, sortBy, sortOrder, sortColumn?.type);
  }, [sortOrder, sortBy, filteredList]);

  const paginatedList = useMemo(() => getCurrentPage(sortedList, page, rowsPerPage), [sortedList, page, rowsPerPage]);

  const fetchData = () => {
    setSelectedRows([]);
    mutate?.();
  };

  useEffect(() => {
    // load device pools
    getAllDevicePools().then(({ data: devicePools }) => {
      if (devicePools?.length > 0) {
        setPoolList(devicePools);
      }
    });
  }, []);

  const getRowSN = (row: DeviceValueTypes) => (row.eiSerialNumber === undefined ? "default" : row.eiSerialNumber);

  const handleEIRowClick = (serialNumber: string) => {
    if (serialNumber !== "default") {
      navigate(`/devices/${serialNumber}`);
    }
  };

  const onNewDevice = () => {
    setValue("serialNumber", "");
    setValue("macAddress", "");
    setIsOpenNewDeviceModal(true);
  };

  const onRemoveDevice = async () => {
    if (selectedRows && selectedRows.length > 0) {
      const isConfirm = await getConfirmation({
        title: "Delete Device?",
        content: "Are you sure to delete selected devices?",
        buttonTxt: "Delete",
      });

      if (isConfirm) {
        if (selectedRows.length > 1) {
          showWarning("Cannot remove multiple devices for now, please select single device!");
        } else {
          try {
            await deleteDevice(selectedRows[0]);
            fetchData();
            showSuccess(`Successfully removed device: ${selectedRows[0]}`);
          } catch (error) {
            console.warn("error", error);
            // error handling
          }
        }
      }
    }
  };

  const onModalClose = (event: never, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick") {
      setIsOpenNewDeviceModal(false);
    }
  };

  const onAddNewDevice = async ({ macAddress, serialNumber, devicePool }: FieldValues): Promise<void> => {
    setIsCreatingNewDevice(true);
    setModalMessage("Creating new device...");

    try {
      const res = await addSingleDeviceAPIHelper({
        mac: macAddress,
        serialNumber,
        devicePool: devicePool || "default",
      });

      if (!res) {
        return;
      }

      if (res.data) {
        showSuccess("Added new device successfully!");
        setIsOpenNewDeviceModal(false);
        // refresh table
        fetchData();
      }
    } catch (error) {
      // error handling
    } finally {
      setIsCreatingNewDevice(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, row: DeviceValueTypes) => {
    const { eiSerialNumber } = row;

    if (!eiSerialNumber) {
      return;
    }

    const newSelected = [...selectedRows];
    const index = selectedRows.indexOf(eiSerialNumber);

    if (event.target.checked) {
      if (index === -1) {
        newSelected.push(eiSerialNumber);
      }
    } else if (index !== -1) {
      newSelected.splice(index, 1);
    }

    setSelectedRows(newSelected);
  };

  const isSelectedRow = (eiSerialNumber: string | undefined) => {
    if (!eiSerialNumber) {
      return;
    }
    return selectedRows.indexOf(eiSerialNumber) !== -1;
  };

  const handleRequestSort = (order: string, orderBy: string) => {
    setSortOrder(order);
    setSortBy(orderBy);
  };

  const onSearch = (column: string, columnType: string, searchContent: any) => {
    console.log("---", column, columnType, searchContent);
    setFilterColumn(column);
    setFilterWord(searchContent);
    setFilterType(columnType);
  };

  const onResetFilter = () => {
    setFilterColumn("");
    setFilterWord("");
    setFilterType("");
    setPage(0);
  };

  const handleUpdateDevice = async (isRefresh: boolean) => {
    handleActionsClose();

    const isOnlineConfirmed = await getConfirmation({
      title: isRefresh ? "Refresh Values" : "Reboot Device",
      content: `This action will be applied to online devices only`,
      buttonTxt: ButtonText.Continue,
    });

    if (!isOnlineConfirmed) {
      return;
    }

    onSearch("online", "option", true);

    const deviceList = getFilteredListNew(deviceData, "online", "option", true);
    const rows = selectedRows.filter((eiSerialNumber) =>
      deviceList.find((x: { eiSerialNumber: string }) => x.eiSerialNumber === eiSerialNumber),
    );

    if (!rows.length) {
      onResetFilter();
      showWarning("There are no devices online.");
      return;
    }
    const isRefreshValues = await getConfirmation({
      title: isRefresh ? "Refresh Values" : "Reboot Device",
      content: isRefresh
        ? "Are you sure to refresh values?"
        : "Are you sure? You will lose connectivity while the device reboots.",
      buttonTxt: ButtonText.Refresh,
    });

    if (!isRefreshValues) {
      onResetFilter();
      return;
    }

    setUpdating(true);
    const requests = rows.map((r) =>
      changeDeviceStatusFromUI({
        eiSerialNumber: r,
        ...(isRefresh ? { getDeviceDetails: true } : { rebootDevice: true }),
      }),
    );
    await Promise.all(requests);
    onResetFilter();
    setUpdating(false);
  };

  const handleLocationTrail = async (isDisable: boolean) => {
    handleActionsClose();
    const isConfirmed = await getConfirmation({
      title: "Location Tracking",
      content: `Are you sure you want to ${isDisable ? "disable" : "enable"} location tracking`,
      buttonTxt: `${isDisable ? ButtonText.Disable : ButtonText.Enable} `,
    });

    if (!isConfirmed) {
      return;
    }

    setUpdating(true);

    const formData = isDisable ? { disableLocationTrail: true } : { enableLocationTrail: true };

    const requests = selectedRows.map((r) =>
      changeDeviceStatusFromUI({
        eiSerialNumber: r,
        ...formData,
      }),
    );
    await Promise.all(requests);

    fetchData();
    setUpdating(false);
  };

  const goToMultipleDevicesPage = () => {
    navigate("/add-multiple-devices");
  };

  return (
    <>
      <PageTitle>Devices</PageTitle>

      <div className={style.barContainer}>
        <div className={style.filterContainer}>
          <TableFilterNew columns={filterColumns} onSearch={onSearch} onReset={onResetFilter} />
        </div>
        <ActionBar className={style.buttonOptions}>
          <Box display="flex">
            <NxtIconButton icon={<AddCircleOutlined />} onClick={onNewDevice} text="New Device" />
            <NxtIconButton icon={exportFileIcon} onClick={getDeviceDataCsv} text="Export Csv" />
            <NxtIconButton
              icon={<DeleteIcon />}
              disabled={selectedRows?.length <= 0}
              onClick={onRemoveDevice}
              text="Remove Device"
            />

            <NxtIconButton
              id="device-actions-button"
              disabled={selectedRows?.length <= 0}
              aria-controls={open ? "device-actions-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              icon={<MoreVertIcon />}
              onClick={handleActionsClick}
              text="Options"
            />

            <Menu
              id="device-actions-menu"
              aria-labelledby="device-actions-button"
              anchorEl={actionsAnchorEl}
              open={open}
              onClose={handleActionsClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleLocationTrail(true)}>Disable Location Tracking</MenuItem>
              <MenuItem onClick={() => handleLocationTrail(false)}>Enable Location Tracking</MenuItem>
              <MenuItem onClick={() => handleUpdateDevice(true)}>Refresh Values</MenuItem>
              <MenuItem onClick={() => handleUpdateDevice(false)}>Reboot Device</MenuItem>
            </Menu>
          </Box>
        </ActionBar>
      </div>

      <CustomTable
        boxShadow
        rowsPerPageOptions={[25, 50, 100]}
        totalCount={totalDeviceCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(pageNum) => setPage(pageNum)}
        onRowsPerPageChange={(rowPerPage) => setRowsPerPage(rowPerPage)}
      >
        <CustomTable.Header columns={DASHBOARD_COLUMNS} onRequestSort={handleRequestSort} />

        <CustomTable.Body isLoading={isLoading || updating} deviceData={paginatedList}>
          {!isLoading &&
            paginatedList &&
            paginatedList.map((row: DeviceValueTypes) => {
              const { eiSerialNumber } = row;
              const isItemSelected = isSelectedRow(eiSerialNumber);

              return (
                row && (
                  <TableRow hover role="checkbox" tabIndex={-1} key={eiSerialNumber} selected={isItemSelected}>
                    <TableBodyCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event) => handleChange(event, row)}
                      />
                    </TableBodyCell>
                    {dataColumns.map((column: DeviceColumnTypes) => {
                      const value = row[column.id as keyof DeviceValueTypes];

                      return (
                        <TableBodyCell
                          size={column.type === COLUMN_TYPE.OPTION ? "small" : "medium"}
                          className={column.className}
                          key={column.id}
                          align={column.align}
                          onClick={() => handleEIRowClick(getRowSN(row))}
                        >
                          {column.format ? column.format(value) : value}
                        </TableBodyCell>
                      );
                    })}
                  </TableRow>
                )
              );
            })}
        </CustomTable.Body>
      </CustomTable>

      <Popup
        open={isOpenNewDeviceModal}
        isNewDevice
        onHeaderClick={goToMultipleDevicesPage}
        onClose={onModalClose}
        disableEscapeKeyDown
        title="New Device"
        primaryBtn={{ text: "Save", onClick: handleSubmit(onAddNewDevice) }}
        secondaryBtn={{ text: "Cancel", onClick: () => setIsOpenNewDeviceModal(false) }}
      >
        {" "}
        <div className={style.popupContentContainer}>
          <StyledTextField
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onAddNewDevice))}
            label="Serial Number"
            {...register("serialNumber", { required: "Serial Number is required", minLength: 11, maxLength: 15 })}
            error={errors.serialNumber}
            style={{ marginTop: 16 }}
          />
          {errors.serialNumber && <FieldError>{errors.serialNumber.message}</FieldError>}
          {errors.serialNumber &&
            (errors.serialNumber.type === "maxLength" || errors.serialNumber.type === "minLength") && (
              <FieldError>Serial Number should be 11, 12, or 15 characters in length</FieldError>
            )}

          <StyledTextField
            style={{ paddingBottom: "32px" }}
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onAddNewDevice))}
            label="MAC Address"
            {...register("macAddress", { required: "MAC Address is required" })}
            error={errors.macAddress}
          />
          {errors.macAddress && <FieldError>{errors.macAddress.message}</FieldError>}
          <CommonSelect
            label="Pool"
            name="devicePool"
            options={poolOptions}
            control={control}
            rules={{ required: "Device Pool is required" }}
            error={errors.devicePool}
          />
          {errors.devicePool && <FieldError>{errors.devicePool.message}</FieldError>}
        </div>
      </Popup>

      <Popup title={modalMessage} open={isCreatingNewDevice}>
        <Spinner />
      </Popup>

      <Confirmation />
    </>
  );
}
